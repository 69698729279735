<template>
	<div id="container" class="container">
		<div id="sub" class="sub detail">
			<div style="display:block" class="layer">
				<div class="layer__dim"></div>
				<div class="layer__full" style="padding-bottom:100px">
					<div class="layer__fullbox">
						<div class="layer__fullheader">
							<nav class="nav">
								<div class="nav__wrap">
									<a class="nav__btn" @click="goBack">
										<i class="icon icon-close"></i>
									</a>
									<p class="nav__item">
										<span class="nav__text">
											{{t('10661')}}
										</span>
									</p>
									<a href="#" class="nav__btn">
									</a>
								</div>
							</nav>
						</div>
						<div class="layer__fullbody">
							<section class="calender">
								<div class="box">
									<DatePicker
      							class="custom-calendar"
										ref="calendar"
										v-model="dateRange"
										color="purple"
										locale="ko"
										is-range
										is-expanded
										:minDate="new Date().getHours() >= 3 ? new Date() : new Date(new Date().setDate(new Date().getDate() - 1))"
										:masks="masks"
										:attributes='attrs'
										:disabled-dates="disabledDates"
										@dayclick="onDayClick"
										:rows="12"
										:popover="{visibility : 'hidden'}"
									/>
									<div id="navigation" class="navigation">
										<div class="btn btns--centered">
											<!-- 기존 publishing -->
											<!-- <button type="button" class="btn btn-confirm">
												10월 14일~10월 15일 1박 선택
											</button> -->

											<!-- [D] 날짜 미 선택시 -->
										<button v-if="selectedDateRange === null" type="button" class="btn btn-disabled">{{t('10661')}}</button>
											<!-- [D] 날짜 선택시 -->
										<button v-else v-on:click="handleSubmit" type="button" class="btn btn-submit">
											{{dateText}}
										</button>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		DatePicker,
	},
	mounted() {
        this.$store.dispatch('mcd00/fetchMcd00', {proc_cd: "06"});
	},
	data() {
		return {
			selectedDateRange: null,
			dateRange: null,
			disabledDates: [],
			masks: {
				title: 'YYYY.MM월'
			}
		};
	},
	computed: {
	 	dateText: function() {
			 return dayjs(this.selectedDateRange.start).format("MM월 DD일")
				+ "~" +
				dayjs(this.selectedDateRange.end).format("MM월 DD일")
				+ " " + dayjs(this.selectedDateRange.end).diff(dayjs(this.selectedDateRange.start), "day") + "박 선택"
		},
		attrs: function() {
			var now_hours = new Date().getHours()
			if(now_hours >= 3){
			 const data = [
				{
					key: 'today',
					dot: true,
					dates: new Date(),
				},
				/* [D] 휴일 설정 */
				{
					highlight : {
						class : 'holiday',
						contentClass:'holiday'
					},
					dates: (this.$store.state.mcd00.mcd00["06"] ?? []).map(data => 
						dayjs(data.code).toDate()
					),
				},
			]
			//console.log(data);
			return data;
		}else{
			 const data = [
				{
					key: 'today',
					dot: true,
					dates: new Date(new Date().setDate(new Date().getDate() - 1)),
				},
				/* [D] 휴일 설정 */
				{
					highlight : {
						class : 'holiday',
						contentClass:'holiday'
					},
					dates: (this.$store.state.mcd00.mcd00["06"] ?? []).map(data => 
						dayjs(data.code).toDate()
					),
				},
			]
			//console.logog(data);
			return data;
		}

		}
	},
	methods: {
		onDayClick(day) {
			if (!day.isDisabled) {
				if (this.dateRange == null) {
					const start = day.date;
					const rangeStart = new Date(
						start.getFullYear(),
						start.getMonth(),
						start.getDate() - 1
					);
					const rangeEnd = new Date(
						rangeStart.getFullYear(),
						rangeStart.getMonth(),
						rangeStart.getDate() + 32 // [D] 예약가능한 날수
					);
					this.disabledDates = [
						{
							start: null,
							end: rangeStart,
						},
						{
							start: rangeEnd,
							end: null,
						},
					];
					this.selectedDateRange = null
				} else {
					this.selectedDateRange = {
						start: this.dateRange.start,
						end: this.dateRange.end,
					};
					this.disabledDates = [];
					this.dateRange = null;
				}
			}
		},
		handleSubmit() {
			this.$store.commit('mhm01/SET_SELECT_DATE', {dateRange: JSON.parse(JSON.stringify(this.selectedDateRange))})
			this.$router.back()
		},
		goBack (){
			this.$router.back()
		}
	},
	setup(){
		
		const { t }= useI18n() 
		return{
			 t,  //번역필수 모듈
		  i18n, 
		}
	}
}
</script>

<style scoped> 
/* Calender Layout*/
.custom-calendar.vc-container {border:none}
.custom-calendar >>> .vc-pane ~ .vc-pane {margin-top:20px}
.custom-calendar >>> .vc-pane-layout {font-family:'Poppins', 'Noto Sans KR';font-size:16px}
.custom-calendar >>> .vc-arrow {display:none}
.custom-calendar >>> .vc-header {display:block;padding:0;padding-bottom:14px}
.custom-calendar >>> .vc-title:hover {opacity:1}
.custom-calendar >>> .vc-weeks {padding:0}
.custom-calendar >>> .vc-weekday:nth-of-type(1) {color:var(--red)}
.custom-calendar >>> .vc-weekday:nth-of-type(7) {color:var(--blue)}
.custom-calendar >>> .vc-weekday {padding-top:20px;padding-bottom:14px;border-bottom:1px solid var(--grey3);border-top:2px solid #000;font-weight:400;color:#000}
.custom-calendar >>> .vc-day {padding:8px 0}
.custom-calendar >>> .vc-day-content {background:none !important}
.custom-calendar >>> .vc-day-content:hover {background:none !important}
.custom-calendar >>> .vc-highlight {background:var(--red) !important;border-color:var(--red) !important;border-radius:0 !important}
.custom-calendar >>> .vc-highlight.vc-highlight-base-middle, .vc-highlight.vc-highlight-base-end, .vc-highlight.vc-highlight-base-start {background:#f5f5f5 !important}
.custom-calendar >>> .vc-highlight {border-radius:inherit !important}
.custom-calendar >>> .vc-day-box-right-center + .vc-day-box-center-center .vc-highlight {border-top-left-radius:5px !important;border-bottom-left-radius:5px !important}
.custom-calendar >>> .vc-day-box-left-center + .vc-day-box-center-center .vc-highlight {border-top-right-radius:5px !important;border-bottom-right-radius:5px !important}
.custom-calendar >>> .is-not-in-month {height:0;padding:0;min-height:0}
.custom-calendar >>> .is-not-in-month .vc-day-content {display:none}
.custom-calendar >>> .vc-bars {display:none}
.custom-calendar >>> .weekday-1 .vc-day-content {color:var(--red)}
.custom-calendar >>> .vc-highlight.holiday {background:none !important}
.custom-calendar >>> .vc-day-content.holiday {color:var(--red) !important}
.custom-calendar >>> .vc-popover-content-wrapper {display:none}
.custom-calendar >>> .calender__button {margin-top:30px;margin-right:48px}
.custom-calendar >>> .vc-day-content.is-disabled {color:var(--gray-400) !important}
</style>